html,body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}
a, button {
	&:focus {
		outline: none;
	}
	&:hover {
		cursor: pointer;
	}
}
.btn {
	width: 350px;
	text-decoration: none;
	text-align: center;
	display: block;
	border-radius: 0;
	border: none;
	line-height: 50px;
	padding: 0 15px;
	text-transform: uppercase;
	letter-spacing: 2px;
	background: black;
	color: white;
	font-size: 0.8rem;
	margin: 0 auto;
	&:hover {
		cursor: pointer;
	}
}
header {
	margin: 40px auto;
	.logo {
		width: 120px;
		height: 120px;
		margin: 0 auto 40px;
		background: url('../../assets/img/peace.png') no-repeat scroll right / contain;
	}
	h3 {
		max-width: 800px;
		margin: 0 auto 40px;
		font-size: 1.2rem;
		box-sizing: border-box;
		padding: 0 40px;
		line-height: 28px;
		font-weight: lighter;
		color: #999;
		text-align: center;
	}
}
#product {
	max-width: 1080px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	img {
		width: 100%;
	}
	.product,
	.print {
		width: 50%;
		&.link {
			margin: 0 0 -5px;
			position: relative;
			&:hover {
				cursor: pointer !important;
				.btn {
					display: block;
				}
			}
			.btn {
				display: none;
				width: 100px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
}
footer {
	margin: 20px auto;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #999;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 0.6rem;
	a {
		display: inline-block;
		transform: translate(0,4px);
		img {
			width: 30px;
		}
	}
}

@media (min-width: 768px) {
	header {
		margin: 80px 0;
		h3 {
			font-size: 2rem;
			line-height: 40px;
			margin-bottom: 60px;
		}
	}
	#product {
		img {
			&.print {
				width: 33.3%;
			}
		}
	}
}